<template>
  <span
    class="flex flex-col gap-1.5"
    :class="{
      'w-6': open,
      'w-full': !open,
    }"
  >
    <span
      class="h-0.5 origin-left rounded-full bg-current transition-all duration-300"
      :class="{
        ' translate-x-1 rotate-45': open,
      }"
    ></span>
    <span
      class="h-0.5 rounded-full bg-current transition-all duration-300"
      :class="{
        'scale-0 opacity-0': open,
      }"
    ></span>
    <span
      class="h-0.5 origin-left rounded-full bg-current transition-all duration-300"
      :class="{
        ' translate-x-1 -rotate-45': open,
      }"
    ></span>
  </span>
</template>
<script lang="ts" setup>
defineProps<{
  open?: boolean
}>()
</script>
